// src/components/Popup.js
import React, { useState } from 'react';

const Popup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded shadow-lg relative">
        <button className="absolute top-0 right-0 m-2 text-gray-600" onClick={onClose}>
          &times;
        </button>
        <h2 className="text-2xl mb-4">¡Obtén un 10% de Descuento!</h2>
        <p className="mb-4">Suscríbete para recibir un 10% de Descuento en tu primer servicio.</p>
        <form>
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">Correo Electrónico</span>
            </label>
            <input type="email" className="input input-bordered w-full" required />
          </div>
          <button type="submit" className="btn btn-primary w-full">Suscribirse</button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
