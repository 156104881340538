import React, { useRef } from 'react';

import { Link } from "react-router-dom";

let rutaCarpeta = 'pictures';

// Nombre de la imagen
let nombreImagen = 'heroimagenreunion.jpeg';

// Construir la URL completa de la imagen
let urlImagen = `/${rutaCarpeta}/${nombreImagen}`;


const nameEnter = <p className='mt-5' style={{ color: '#436ADB' }} >SOFTDEV G </p>


export const HeroHome = ({ servicesRef, isMobile }) => {
    const ancho = isMobile ? '95%' : '50%'

    const handleClickVerMas = () => {
        if (servicesRef.current) {
            servicesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div style={{ backgroundColor: 'white' }} className="hero min-h-screen bg-base-200">
            <div className="hero-content flex-col lg:flex-row-reverse">
                <div style={{ maxWidth: ancho, margin: '0 auto' }}>
                    <img
                        src={urlImagen}
                        alt="Descripción de la imagen"
                        style={{ width: '100%', height: 'auto', display: 'block', borderRadius: '8px' }}
                    />
                </div>
                <div>
                    <h1 className="text-5xl font-bold"><p className='mt-5' style={{ color: '#4A93CF' }} >AGENTE DE MARKETING </p></h1>
                    <p className="py-6">Elevando tus ventas</p>
                    <button className="btn btn-primary"  onClick={handleClickVerMas}>Ver mas<span className="loading loading-ring loading-md"></span><Link to="/#services"></Link></button>
                </div>
            </div>
        </div>



    )
}