import React,  { useState }from 'react'
import { FaMap, FaInbox, FaMobile } from 'react-icons/fa'


const contactInfo = {
    address: '18238 70th Ave Hialeah Florida - USA',
    phoneNumber: '+3059034585',
    emailContact: 'softdevgenius@gmail.com'
}
  

export const ContactHome = ({ children }) => {
    return (
        <div style={{ backgroundColor: '#FBF9FC', borderWidth: 0 }} id="contact">
            <div className="bg-gray">
                <div  className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 gap-x-40 pt-16 pb-16">
                    <h2 className="text-4xl font-bold text-center">Contactanos</h2>
                    {/* <p className="pt-6 text-base max-w-4xl text-center m-auto dark:text-neutral-500">
                        Creación de aplicaciones web y móviles personalizadas para satisfacer las necesidades específicas de tu empresa.
                    </p>
                    <p className="pt-6 text-base max-w-4xl text-center m-auto dark:text-neutral-500">
                        Implementación de tecnologías modernas para garantizar la escalabilidad y el rendimiento óptimo.
                    </p> */}

                    <div
                        className="grid grid-cols-1 md:grid-cols-2 md:gap-x-16 lg:gap-x-28 items-center"
                    >
                        <div
                            className="pt-8 md:pt-16 order-1"
                        >
                            <h1 className="text-2xl font-bold mb-5">Escribenos</h1>
                            <p className="font-medium space-y-1 flex-1">
                                ¿Tener algo que decir? Estamos aquí para ayudar.
                            </p>
                            <p className="font-medium space-y-1 flex-1">
                                Rellene el formulario o envíe un correo electrónico o llame al teléfono.
                            </p>
                            <div className="flex items-center mt-4 space-x-2 text-dark-600">
                                <FaMap className="w-4 h-4" />
                                <span>{contactInfo.address}</span>
                            </div>
                            <div className="flex items-center mt-4 space-x-2 text-dark-600">
                                <FaInbox className="w-4 h-4" />
                                <span>{contactInfo.emailContact}</span>
                            </div>
                            <div className="flex items-center mt-4 space-x-2 text-dark-600">
                                <FaMobile className="w-4 h-4" />
                                <span>{contactInfo.phoneNumber}</span>
                            </div>

                        </div>
                        <div
                            className="order-2 pt-8 md:pt-16 mr-0 2xl:-mr-40"
                        >
                            <div className="flex flex-col items-center mr-5">
                                <input type="text" placeholder="Nombre y apellido" className="input input-bordered w-full max-w-xl mb-4" />
                                <input type="text" placeholder="Correo electronico" className="input input-bordered w-full max-w-xl mb-4" />
                                <textarea placeholder="Mensaje" className="textarea textarea-bordered textarea-md w-full max-w-xl mb-4" ></textarea>
                                <button className="btn btn-active btn-accent">Enviar mensaje</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

