import React, { useEffect, useState }from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { Layout } from './components/Layout';
import HomePage from './pages/HomePage';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Puedes ajustar este valor según tus necesidades
  };

  useEffect(() => {
    handleResize(); // Llamamos a la función al cargar la página
    window.addEventListener('resize', handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const asd = [ 2 , 3 , 4, 6];
  // console.log(isMobile)
  return (
    <ThemeProvider>
        <Layout isMobile={isMobile}>  
          <Routes>
            <Route path='/' element={<HomePage isMobile={isMobile}/>} />
          </Routes>
        </Layout>
    </ThemeProvider>
  );
}

export default App;
