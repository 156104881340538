import { createContext, useContext, useState } from "react";

const themeContext = createContext()
const changeThemeContext = createContext()
const listThemeContext = createContext()

export function useThemeContext() {
    return useContext(themeContext)
}

export function useChangeThemeContext() {
    return useContext(changeThemeContext)
}

export function useListThemeContext() {
    return useContext(listThemeContext)
}

export function ThemeProvider({children}) {
    const [themeID, setThemeID] = useState(0)
    const [theme] = useState(["light", "dark", "corporate", "winter"])

    const changeTheme = (thmid) => {

        setThemeID(thmid)
    }

    return(
        <themeContext.Provider value={theme[themeID]}>
            <changeThemeContext.Provider value={changeTheme}>
                <listThemeContext.Provider value={theme}>
                    {children}
                </listThemeContext.Provider>
            </changeThemeContext.Provider>
        </themeContext.Provider>
    )
}