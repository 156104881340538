import React from "react";
import { useChangeThemeContext } from "../contexts/ThemeContext";
import { useListThemeContext } from "../contexts/ThemeContext";
import { Link } from "react-router-dom";
import { FaBars, FaSwatchbook, FaBrush } from 'react-icons/fa'
import profile from '../media/profile';

let rutaCarpeta = '../';

// Nombre de la imagen
let nombreImagen = 'upgradixlogoLarge.png';

// Construir la URL completa de la imagen
let urlImagen = `${process.env.PUBLIC_URL}/${rutaCarpeta}/${nombreImagen}`;

const navigation = [
  { name: "Servicios", href: "#services", current: false },
  { name: "Sobre Nosotros", href: "#aboutus", current: false },
  { name: "Faq", href: "#faq", current: false },
  { name: "Contact", href: "#contact", current: false },
];

export const Topnav = ({ isMobile }) => {
  const changeTheme = useChangeThemeContext()
  const listThemes = useListThemeContext()

  // const handleResize = () => {
  //   setIsMobile(window.innerWidth <= 768); // Puedes ajustar este valor según tus necesidades
  // };

  // useEffect(() => {
  //   handleResize(); // Llamamos a la función al cargar la página
  //   window.addEventListener('resize', handleResize);

  //   // Limpiar el event listener al desmontar el componente
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);


  return (
    <div style={{ backgroundColor: 'white', position: 'fixed', zIndex: '2' }} className="p-0">
      <div style={{ position: 'fixed', borderWidth: 1, width: '100%' }} className="navbar bg-base-100 ">

        <div className="flex-1" style={{ backgroundColor: 'transparent' }}>
          {!isMobile ? (
            <a href={'/'} className="btn btn-ghost text-xl">UPGRADIX</a>
          ) : <div style={{ width: 50, backgroundColor: 'transparent' }}>
            <a href={'/'} className="btn-ghost ">
              <img
                src={urlImagen}
                alt="Descripción de la imagen"
              />
            </a>

          </div>}


          {/* <a href={'/'} className="btn btn-ghost text-xl">SOFTDEV G</a> */}
          {!isMobile && navigation.map((item) => (
            <li key={item.name} className='link link-hover m-4 list-none'>
              <a style={{ fontStyle: 'bold', }} href={item.href}>{item.name}</a>
            </li>
          ))}
        </div>
        <div className="flex-none">
          {isMobile && (
            <div className="dropdown dropdown-end">
              <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
              </div>
              <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                {/* <li><a>Homepage</a></li>
                <li><a>Portfolio</a></li>
                <li><a>About</a></li> */}
                {navigation.map((item) => (
                  <li
                    key={item.name}
                    className="link link-hover"
                  >
                    <a href={item.href}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


