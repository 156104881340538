import React,  { useState }from 'react'



let rutaCarpeta = '../pictures';

// Nombre de la imagen
let nombreImagen = 'heroimagenreunion.jpeg';

// Construir la URL completa de la imagen
let urlImagen = `${process.env.PUBLIC_URL}/${rutaCarpeta}/${nombreImagen}`;

export const AboutUsHome = ({ children }) => {
    const [textaboutus, setTextaboutus] = useState("Bienvenido a SoftDev Genius, tu socio estratégico en el fascinante mundo del desarrollo de software. Fundada hace tan solo un año, nuestra empresa irradia juventud y energía, respaldada por una pasión inquebrantable por la innovación y la excelencia tecnológica.")
    const [texthistory, setTexthistory] = useState("En el corazón de Miami, un grupo de mentes creativas y apasionadas decidió unirse para dar vida a una visión compartida: la creación de soluciones de software que trasciendan las expectativas convencionales. Aunque somos jóvenes en años, nuestra experiencia se extiende más allá de las páginas del calendario.")
    const [textenfoque, setTextenfoque] = useState("En SoftDev Genius, no solo desarrollamos software; creamos experiencias digitales que transforman ideas en realidad. Nos enorgullece ser artesanos digitales, enfocados en cada detalle para garantizar que cada línea de código, cada diseño y cada función contribuyan a una experiencia de usuario excepcional.")
    const [textcompromiso, setTextcompromiso] = useState("En SoftDev Genius, no solo creamos software; nuestra misión es proporcionar soluciones que no solo resuelvan los desafíos actuales, sino que también se adelanten a las necesidades futuras. Nos comprometemos a ofrecer transparencia, comunicación abierta y resultados excepcionales en cada paso del camino.")
    const [textunete, setTextunete] = useState("En SoftDev Genius, estamos emocionados por lo que el futuro nos depara. Invitamos a empresas y emprendedores visionarios a unirse a nosotros en este emocionante viaje. Juntos, podemos transformar ideas en aplicaciones poderosas, sitios web intuitivos y soluciones de software que marcarán la pauta en la era digital.")

    return (
        
        <div style={{ backgroundColor: 'white'}} id="aboutus">
            <div >
                <div className="text-center py-10" >
                    <div className="max-w-md mx-auto" >
                        <h1 className="text-4xl font-bold">Sobre Nosotros</h1>
                        <p className="py-6">
                            {textaboutus}
                        </p>
                    </div>
                </div>

                <div className="max-w-5xl mx-auto" >
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                        <div className="text-center card p-6">
                            <h1 className="text-2xl font-bold">Nuestra Historia:</h1>
                            <p className="py-4">
                                {texthistory}
                            </p>
                        </div>

                        <div className=" text-center card p-6" >
                            <h1 className="text-2xl font-bold">Nuestro Enfoque:</h1>
                            <p className="py-4">
                                {textenfoque}
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                        <div className=" text-center card p-6">
                            <h1 className="text-2xl font-bold">Nuestro Compromiso:</h1>
                            <p className="py-4">
                                {textcompromiso}
                            </p>
                        </div>

                        <div className=" text-center card p-6">
                            <h1 className="text-2xl font-bold">Únete a Nuestra Historia:</h1>
                            <p className="py-4">
                                {textunete}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    )
}



// <div style={{ backgroundColor: '#FBF9FC' }}>
//             <div style={{ backgroundColor: 'transparent' }} className="hero text-center">
//                 <div className="max-w-md mt-10 mb-10">
//                     <h1 className="text-5xl font-bold">Sobre Nosotros</h1>
//                     <p className="py-6">Bienvenido a SoftDev Genius, tu socio estratégico en el fascinante mundo del desarrollo de software. Fundada hace tan solo un año, nuestra empresa irradia juventud y energía, respaldada por una pasión inquebrantable por la innovación y la excelencia tecnológica.</p>
//                 </div>
//             </div>
//             <div>
//                 <div style={{ maxWidth: '80%', margin: '0 auto', backgroundColor: 'transparent' }}>
//                     <div className="flex flex-col w-full lg:flex-row mt-2">
//                         <div className="grid flex-grow h-32 card bg-base-300 rounded-box place-items-center m-5">
//                             <h1 className="text-2xl font-bold">Sobre Nosotros</h1>
//                             <p className="py-6">
//                                 En el corazón de [Nombre de tu Ciudad o Región], un grupo de mentes creativas y apasionadas decidió unirse para dar vida a una visión compartida: la creación de soluciones de software que trasciendan las expectativas convencionales. Aunque somos jóvenes en años, nuestra experiencia se extiende más allá de las páginas del calendario.
//                             </p>
//                         </div>

//                         <div className="grid flex-grow h-32 card bg-base-300 rounded-box place-items-center m-5 ">content</div>
//                     </div>
//                     <div className="flex flex-col w-full lg:flex-row">
//                         <div className="grid flex-grow h-32 card bg-base-300 rounded-box place-items-center m-5">content</div>
                       
//                         <div className="grid flex-grow h-32 card bg-base-300 rounded-box place-items-center m-5">content</div>
//                     </div>
//                 </div>
//             </div>
//         </div>