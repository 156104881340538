import React, { useRef, useState, useEffect } from 'react';
import { HeroHome } from '../components/HeroHome';
import { AboutUsHome } from '../components/AboutUsHome';
import { ServicesHome } from '../components/ServicesHome';
import { ContactHome } from '../components/ContactHome';
import { FaqHome } from '../components/FaqHome';

import Popup from '../components/PopUp';

function HomePage({ isMobile }) {

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const servicesRef = useRef(null);


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupOpen(true);
    }, 5000); // Mostrar el pop-up después de 10 segundos
    return () => clearTimeout(timer);
  }, []);
  
  return (
    // style={{backgroundColor: 'white'}}
    <div>
      <HeroHome servicesRef={servicesRef}  isMobile={isMobile}/>
      <div ref={servicesRef}>
                <ServicesHome />
            </div>
      <AboutUsHome />
      <ContactHome />
      <FaqHome />
      <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
    </div>
    
  )
}

export default HomePage