import React, { useState } from 'react'

const faqitems = [
    {
        idfaq: '1',
        question: "¿Cuál es su política de reembolso?",
        response:
            "Si no está satisfecho con su compra por algún motivo, envíenos un correo electrónico dentro de los 90 días y le reembolsaremos el importe total.",
    },
    {
        idfaq: '2',
        question: "¿Ofrecen soporte técnico?",
        response:
            "No, no ofrecemos soporte técnico para descargas gratuitas. Compre un plan de soporte para obtener 6 meses de soporte.",
    }
];

export const FaqHome = ({ children }) => {
    return (
        <div style={{ backgroundColor: 'white', borderWidth: 0 }} id="faq">
            <div className="bg-gray">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 gap-x-40 pt-16 pb-16">
                    <h2 className="text-4xl font-bold text-center">Preguntas frecuentes</h2>
                    {faqitems.map((faq) => (
                        <div key={faq.idfaq} className="collapse bg-base-200 mt-8">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                {faq.question}
                            </div>
                            <div className="collapse-content">
                                <p>{faq.response}</p>
                            </div>
                        </div>
                    ))}
                    

                </div>
            </div>
        </div>
    )
}

