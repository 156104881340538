import React,  { useState }from 'react'

const contents = [
    {
      title: "Desarrollo de Aplicaciones Web y Móviles:",
      features: [
        "Creación de aplicaciones web y móviles personalizadas para satisfacer las necesidades específicas de tu empresa.",
        "Implementación de tecnologías modernas para garantizar la escalabilidad y el rendimiento óptimo.        ",
      ],
      image: "/pictures/servicios/Handcoding-pana.svg",
      order: false,
    },
    {
      title: "Integración de Sistemas:",
      features: [
        "Conexión fluida de sistemas existentes para mejorar la eficiencia operativa.",
        "Implementación de API y soluciones de middleware para una comunicación perfecta entre aplicaciones.",
      ],
      image: "/pictures/servicios/Emails-pana.svg",
      order: true,
    },
    {
      title: "Consultoría en Tecnología y Arquitectura:",
      features: [
        "Asesoramiento experto en la selección de tecnologías adecuadas.",
        "Diseño de arquitecturas de software robustas y adaptables.",
      ],
      image: "/pictures/servicios/Productquality-pana.svg",
      order: false,
    },
  ];

export const ServicesHome = ({ children, ref }) => {
    return (
        <div style={{ backgroundColor: '#FBF9FC', borderWidth: 0 }} id="services">
            <div className="bg-gray">
                <div  className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 gap-x-40 pt-16 pb-16">
                    <h2 className="text-4xl font-bold text-center">Nuestros Servicios</h2>
                    {/* <p className="pt-6 text-base max-w-4xl text-center m-auto dark:text-neutral-500">
                        Creación de aplicaciones web y móviles personalizadas para satisfacer las necesidades específicas de tu empresa.
                    </p>
                    <p className="pt-6 text-base max-w-4xl text-center m-auto dark:text-neutral-500">
                        Implementación de tecnologías modernas para garantizar la escalabilidad y el rendimiento óptimo.
                    </p> */}

                    {contents.map((content) => (
                        <div
                            key={content.title}
                            className="grid grid-cols-1 md:grid-cols-2 md:gap-x-16 lg:gap-x-28 items-center"
                        >
                            <div
                                className={`pt-8 md:pt-16 ${content.order ? "order-1 md:order-2" : "order-1"
                                    }`}
                            >
                                <h1 className="text-2xl font-bold mb-5">{content.title}</h1>

                                <ul className="font-medium space-y-1 flex-1">
                                    {content.features.map((feature) => (
                                        <li key={feature} className="leading-6 flex">
                                            <span className="ml-3 dark:text-neutral-500">
                                                ● {feature}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div
                                className={`order-1 pt-8 md:pt-16 ${content.order ? "ml-0 2xl:-ml-40" : "mr-0 2xl:-mr-40"
                                    }`}
                            >
                                <div className="flex items-center">
                                    <img
                                        src={content.image}
                                        alt="Image description"
                                        width={1920}
                                        height={1080}
                                        quality={75}
                                        sizes="100vw"
                                        priority
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}



// <div style={{ backgroundColor: '#FBF9FC' }}>
//             <div style={{ backgroundColor: 'transparent' }} className="hero text-center">
//                 <div className="max-w-md mt-10 mb-10">
//                     <h1 className="text-5xl font-bold">Sobre Nosotros</h1>
//                     <p className="py-6">Bienvenido a SoftDev Genius, tu socio estratégico en el fascinante mundo del desarrollo de software. Fundada hace tan solo un año, nuestra empresa irradia juventud y energía, respaldada por una pasión inquebrantable por la innovación y la excelencia tecnológica.</p>
//                 </div>
//             </div>
//             <div>
//                 <div style={{ maxWidth: '80%', margin: '0 auto', backgroundColor: 'transparent' }}>
//                     <div className="flex flex-col w-full lg:flex-row mt-2">
//                         <div className="grid flex-grow h-32 card bg-base-300 rounded-box place-items-center m-5">
//                             <h1 className="text-2xl font-bold">Sobre Nosotros</h1>
//                             <p className="py-6">
//                                 En el corazón de [Nombre de tu Ciudad o Región], un grupo de mentes creativas y apasionadas decidió unirse para dar vida a una visión compartida: la creación de soluciones de software que trasciendan las expectativas convencionales. Aunque somos jóvenes en años, nuestra experiencia se extiende más allá de las páginas del calendario.
//                             </p>
//                         </div>

//                         <div className="grid flex-grow h-32 card bg-base-300 rounded-box place-items-center m-5 ">content</div>
//                     </div>
//                     <div className="flex flex-col w-full lg:flex-row">
//                         <div className="grid flex-grow h-32 card bg-base-300 rounded-box place-items-center m-5">content</div>
                       
//                         <div className="grid flex-grow h-32 card bg-base-300 rounded-box place-items-center m-5">content</div>
//                     </div>
//                 </div>
//             </div>
//         </div>