import React from 'react'
import { useThemeContext } from '../contexts/ThemeContext'
// import { Sidebar } from './Sidebar'
import { Topnav } from './Topnav'

import { MainFooter } from './MainFooter'

export const Layout = ({children, isMobile}) => {
  const theme = useThemeContext()
  return (
    // <div className="relative flex min-h-screen bg-base-200" data-theme={theme}>
    //     <Sidebar>
    //         <Topnav/>
    //         <div className="p-4">
    //             {children}
    //         </div>
    //     </Sidebar>
    // </div>

    <div className="flex-1 bg-base-200" data-theme={theme}>
      <Topnav isMobile={isMobile} />
      <div style={{ maxWidth: '100%'}} className="p-0">
        {children}
      </div>
      <MainFooter />

      {/* <HomePage /> */}
    </div>
  )
}
